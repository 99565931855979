<template>
  <div class="py30 main-container">
    <div class="row center-xs">
      <div>
        <h2 class="center-xs main-title">
          {{ $t("Why choose us?") }}
        </h2>
      </div>
    </div>
    <div class="row center-xs">
      <div class="col-xs-12 col-lg-3 block-container" v-for="block in blocks" :key="block.title">
        <div class="center-xs img-container">
          <img v-lazy="block.img" alt="icon" width="55" height="55">
        </div>
        <h3 class="center-xs block-title">
          {{ $t(block.title) }}
        </h3>
        <p class="center-xs block-description">
          {{ $t(block.description) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorthChooseUsBlock',
  data () {
    return {
      blocks: [
        {
          img: '/assets/sprzedaj-szybko.svg',
          title: 'Sell it fast',
          description: 'Most items sell within 30 days'
        },
        {
          img: '/assets/zarabiaj-wiecej.svg',
          title: 'Earn more',
          description: 'Enjoy the lowest commission rate among other resellers'
        },
        {
          img: '/assets/nie-martw-sie-o-nic.svg',
          title: 'You don’t have to worry about anything!',
          description: 'We take care of every step of the way to sale your product'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  $color-gray: #F5F5F5;

  .main-container {
    background-color: $color-gray;
  }
  .main-title {
    font-size: 35px;
    font-weight: 400;
    margin: 0;
    @media (max-width: 1025px) {
      font-size: 22px;
    }
    @media (max-width: 992px) {
      inline-size: 139px;
    }
  }
  .block-container {
    margin: 30px auto 0 auto;
  }
  .block-title {
    font-size: 17px;
    font-weight: bold;
    font-family: 'Poppins';
    text-transform: uppercase;
    letter-spacing: 1.26px;
    @media (max-width: 1025px) {
      font-size: 14px;
      letter-spacing: 1.04px;
    }
  }
  .block-description {
    font-size: 12px;
    box-sizing: border-box;
    inline-size: 218px;
    margin: auto;
  }
  .img-container {
    width: 55px;
    height: 55px;
    margin: auto;
  }
</style>
